/**
 * Layout/footer stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */


/**
 * 1) Block
 */

.footer {
  background-color: #47484c;
  font-weight: $font-regular;

  .button{
    width: 100%;
  }

  @include respond-max(767px) {
    text-align: center;
  }

  h1, h2, h3, h4, h5, h6, p, a {
    color: $color-white;
  }

  h3 {
    font-size: 18px !important;
  }

  .copyright {
    background-color: #535353;
    padding: 10px 0;
    margin-bottom: 0;
    font-size: 14px;

    p {
      margin-bottom: 0;
      color: $color-text;
    }

    .colofon {
      color: $color-white;
      padding: 0 10px;
      line-height: 15px;
      min-height: 15px;
      position: relative;
      top: -1px;

      @include respond-min($sm) {
        display: inline-block;
        float: left;
      }

      a {
        font-weight: $font-light;
        text-decoration: none;
        color: $color-white !important;
        opacity: 0.6;
        font-size: 13px;
        fill: white;

        @include respond-min($md) {
          &:hover {
            opacity: 1;
          }
        }

        .text {
          display: inline-block;
        }

        svg {
          display: inline-block;
          height: 13px;
          width: 53px;
          top: 5px;
          position: relative;
          margin-left: 3px;
          path{
            fill: white!important;
          }
        }
      }

      @include respond-max(767px) {
        padding: 20px 0 !important;
        text-align: center !important;
        border-top: 1px solid rgba($color-text, .3);
      }
    }

    .footer__nav {

      .nav__item {
        border-left: 1px solid rgba($color-text, .3);
        line-height: 15px;
        min-height: 15px;
        float: left;
        padding: 0 10px;

        @include respond-max(767px) {
          text-align: center;
          border-top: 1px solid rgba($color-text, .3);
          display: block;
          width: 100%;
          float: none;
          border-left: 0;
          padding: 10px 0;

          &:first-child {
            border: 0;
          }
        }

        &:first-child {
          border: 0;
          padding-left: 0px;
        }

        .nav__sub {
          z-index: 0 !important;
        }

        .nav__link {
          text-decoration: none;
          font-size: 13px;
          padding: 0;
          cursor: pointer;
          line-height: 15px;
          min-height: 15px;
          color: $color-white;

          @include respond-min($md) {
            &:hover {
              color: $color-primary;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .address {
    font-size: 14px;

    h3 {
      font-weight: $font-bold !important;
      margin-bottom: 10px;
    }

    p, br {
      margin-bottom: 10px;
    }

    a {
      text-decoration: none;

      .fa, .fab, .fal, .far, .fas {
        color: $color-white;
        margin-right: 5px;
      }

      @include respond-min($md) {
        &:hover {
          text-decoration: underline;
          color: $color-white;
        }
      }
    }

  }

  .social {
    @include respond-max(767px) {
      border-top: 1px solid rgba($color-white, .3);
      padding-top: 30px;
      margin-top: 30px;
    }

    .fab {
      font-size: 22px;
      display: inline-block;
      text-decoration: none;
      margin-right: 5px;
      color: $color-white;

      @include respond-min($md) {
        &:hover {
          color: rgba($color-white, .5);
        }
      }

    }
  }

  .menu {

    .columns {
      ul {
        z-index: 0 !important;

        @include respond-min($sm) {
          columns: 2;
        }
      }
    }

    @include respond-max(767px) {
      text-align: center !important;
      border-top: 1px solid rgba($color-white, .3);
      padding-top: 30px;
      margin-top: 30px;
    }

    .nav__secondary, .nav__primary, .nav__sub {
      display: block;
      position: relative;
      background: none !important;
      z-index: 0 !important;

      @include respond-max(480px) {
        text-align: center !important;
      }
    }

    .nav__item {
      display: block;
      width: 100%;
      line-height: 25px;
      min-height: 25px;

      .sub-toggle {
        display: none !important;
      }

      @include respond-min($md) {
        &:hover {
          .nav__link {
            color: $color-white;
            text-decoration: underline;
          }
        }
      }

      .nav__link {
        text-decoration: none;
        font-size: 14px;
        display: block;
        width: 100%;
        line-height: 25px;
        min-height: 25px;
        color: $color-white;
        padding: 0;
      }

      .sub-toggle {
        display: none !important;
      }

      ul, .nav__sub {
        display: none !important;
        z-index: 0 !important;
      }
    }

    .nav__primary{
      text-align: left!important;
    }

  }
}


/**
 * 2) Element
 */


/**
 * 3) Modifier
 */
