/**
 * Layout/header stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

@include respond-max(991px){
  .navigation-bar{
    top: -100vh;
    display: none;
    opacity: 0;
    transition: all 0.3s ease-in;
    &.open{
      display: block;
      top: 0;
      opacity: 1;
    }
  }
}

@include respond-max(767px){
  .topbar-cta{
    display: none;
  }
}

.header {
  width: 100%;
  height: 75px;
  background-color: $color-white;
  position: fixed;
  top: 0;
  z-index: $z-index-5;
  transition: all .3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);

  .language{
    .nav__item {
      float: left;
      margin-right: 10px;
      > .nav__link {
        padding: 0 !important;
        img{
          width: auto!important;
        }
      }
    }
  }

  .container {
    position: relative;
  }

  @include respond-min($md) {
    height: 120px;
  }

  .add__padding {
    padding-right: 74px;

    @include respond-between($md, 1199px) {
      padding-right: 61px;
    }
  }

  .user {
    transition: all .3s ease;
    float: right;
    height: 110px;
    line-height: 110px;
    padding: 0 25px;

    a {
      font-size: 26px;
      text-decoration: none;
      color: $color-black;

      @include respond-between($md, 1199px) {
        font-size: 24px !important;
      }

      @include respond-min($md) {
        &:hover {
          color: $color-secondary;
        }
      }
    }

    @include respond-min($xs) {
      border-left: 1px solid $color-bg;
    }

    @include respond-between($md, 1199px) {
      padding: 0 20px !important;
    }

    @include respond-max(991px) {
      height: 74px;
      line-height: 74px;
    }

    @include respond-max(480px) {
      padding: 0;
    }

  }

  .cart {
    transition: all .3s ease;
    float: right;
    height: 110px;
    line-height: 110px;
    padding: 0 25px;

    a {
      font-size: 24px;
      text-decoration: none;
      color: $color-primary;
      position: relative;

      @include respond-between($md, 1199px) {
        font-size: 22px !important;
      }

      @include respond-min($md) {
        &:hover {
          color: $color-secondary;
        }
      }

      .amount {
        position: absolute;
        top: -10px;
        right: -15px;
        border-radius: 100%;
        background-color: $color-bg;
        color: $color-text;
        height: 22px;
        min-width: 22px;
        line-height: 22px;
        font-size: 13px;
        text-align: center;
        font-weight: $font-bold;
        font-family: $font-sans;
      }
    }

    @include respond-between($md, 1199px) {
      padding: 0 20px !important;
    }

    @include respond-max(991px) {
      height: 74px;
      line-height: 74px;
    }

    @include respond-min($xs) {
      border-left: 1px solid $color-bg;
    }

    @include respond-max(480px) {
      padding: 0 20px 0 15px;
    }
  }

}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */

.header--small {
  height: 75px;
  padding: 0;
  transition: all .3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);

  .user, .cart {
    transition: all .3s ease;
    height: 75px;
    line-height: 75px;
  }

  .language {
    transition: all .3s ease;
    min-height: 75px;
    line-height: 75px;
    .nav__item {
      > .nav__link {
        padding: 0 !important;
      }
    }
  }

  .searchbar .search button, .searchbar .search, .searchbar .search.active .box input {
    transition: all .3s ease;
    height: 75px;
    line-height: 75px;
    top: 0;
  }

  .nav {
    padding-top: 10px;
    transition: all .3s ease;

    .nav__primary {
      .nav__item {
        > .nav__link {
          transition: all .3s ease;
          padding-bottom: 17px;
        }
      }
    }
  }

  .logo {
    transition: all .3s ease;
    margin-top: 10px;

    svg {
      width: 200px;
      height: 50px;
      transition: all .3s ease;
    }
  }

}


.top__bar {
  background-color: $color-bg;

  h1 {
    font-size: 32px;
  }
}