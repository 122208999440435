/**
 * Base/typography stylesheet
 *
 * Table of contents
 * 1) Headings
 * 2) Extras
 * 3) Resets
 */

/**
 * 1) Headings
 */

h1, .t-alpha,
h2, .t-beta,
h3, .t-charlie,
h4, .t-delta {
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.25;
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
}

h1, .t-alpha {
  font-size: 46px;
  font-weight: $font-black;
}

h2, .t-beta {
  font-size: 36px;
  font-weight: $font-black;
}

h3, .t-charlie {
  font-size: 28px;
  font-weight: $font-bold;
}

h4, .t-charlie {
  font-size: 24px;
  font-weight: $font-bold;
}

@include respond-max(767px){
  h1{
    font-size: 32px;
  }
  h2{
    font-size: 26px;
  }
}

/**
 * 2) Extras
 */

/**
 * 3) Resets
 */

blockquote,
pre,
p,
ul,
ol,
dl,
hr {
  margin-top: 0;
}

blockquote,
pre,
p,
dl,
hr,
table {
  margin-bottom: 20px;
}

ul > :last-child,
ol > :last-child,
dl > :last-child,
blockquote > :last-child {
  margin-bottom: 0;
}
